.dark-theme .dropdown-menu {
  background-color: var(--component-background-dark-theme);
  border: 1px solid var(--main-background-dark-theme);
  box-shadow: var(--icon-box-shadow-dark-theme);
}

.dark-theme .dropdown-item {
  color: var(--font-color-dark-theme);
}

.dark-theme .dropdown-divider {
  border-top: 1px solid var(--main-background-dark-theme);
}

.dark-theme .preview-list .preview-item:hover {
  border-bottom: none;
}

.dark-theme .dropdown-item:hover,
.dark-theme .dropdown-item:focus {
  color: var(--font-color-dark-theme);
  background-color: var(--submenu-hover-background-dark-theme);
}

.dark-theme .dropdown-header {
  color: var(--font-color-dark-theme);
}

.dark-theme .dropdown-item-text {
  color: var(--font-color-dark-theme);
}

.dark-theme .nav-tabs .nav-link.active,
.dark-theme .nav-tabs .nav-item.show .nav-link {
  color: var(--font-color-dark-theme);
}

.dark-theme .text-body {
  color: var(--font-color-dark-theme);
}

.dark-theme .table-hover tbody tr:hover {
  color: var(--font-color-dark-theme);
  background-color: var(--main-background-dark-theme);
}

.dark-theme .footer {
  background: var(--submenu-hover-background-dark-theme)
}

body .container-scroller.dark-theme {
  background: var(--main-background-dark-theme);
  color: var(--font-color-dark-theme);
}

.dark-theme .doc-header {
  background: var(--main-background-dark-theme)
}

.dark-theme .card {
  box-shadow: var(--icon-box-shadow-dark-theme);
  -webkit-box-shadow: var(--icon-box-shadow-dark-theme);
  -moz-box-shadow: var(--icon-box-shadow-dark-theme);
  -ms-box-shadow: var(--icon-box-shadow-dark-theme);
  background-image: none;
  background-color: var(--component-background-dark-theme);
  border: none;
}

.dark-theme .card .card-title {
  color: var(--font-color-dark-theme);
}

.dark-theme .dropify-wrapper .dropify-message span.file-icon:before {
  color: var(--font-color-light-theme);
}

.dark-theme .dropify-wrapper .dropify-message p {
  color: var(--font-color-light-theme);
}

.dropzone .dz-message span {
  color: var(--font-color-dark-theme);
}

.dark-theme .ajax-upload-dragdrop span b {
  color: var(--font-color-light-theme);
}

.dark-theme .profile-navbar .nav-item .nav-link {
  color: var(--font-color-dark-theme);
}

.dark-theme .content-wrapper {
  background: var(--main-background-dark-theme);
}

.dark-theme .sidebar {
  background: var(--main-background-dark-theme)
}

.dark-theme .sidebar .nav .nav-item .nav-link i.menu-icon {
  color: var(--menu-icon-dark-theme) !important;
}

.dark-theme .sidebar .nav .active.nav-link,
.nav-item.active {
  color: var(--active-link-color-dark-theme) !important;
}

.dark-theme .sidebar .nav .nav-item .nav-link i.menu-icon:after {
  background-image: none;
  background-color: var(--component-background-dark-theme);
  box-shadow: var(--icon-box-shadow-dark-theme);
}

.dark-theme .sidebar .nav .nav-item .nav-link .menu-title.documentation {
  background: var(--main-background-dark-theme)
}

.dark-theme .sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link {
  background: var(--main-background-dark-theme)
}

.dark-theme .sidebar .nav.sub-menu {
  background-image: noen;
  background: var(--main-background-dark-theme);
  box-shadow: var(--icon-box-shadow-dark-theme);
  -webkit-box-shadow: var(--icon-box-shadow-dark-theme);
  -moz-box-shadow: var(--icon-box-shadow-dark-theme);
  -ms-box-shadow: var(--icon-box-shadow-dark-theme);

}

.dark-theme .navbar .navbar-brand-wrapper {
  background: var(--main-background-dark-theme)
}

.dark-theme .navbar .navbar-menu-wrapper {
  background: var(--main-background-dark-theme);
  color: var(--font-color-dark-theme);
}

.dark-theme .navbar .navbar-menu-wrapper .navbar-toggler {
  background-image: none;
  background: var(--component-background-dark-theme);
  box-shadow: var(--icon-box-shadow-dark-theme);
  -webkit-box-shadow: var(--icon-box-shadow-dark-theme);
  -moz-box-shadow: var(--icon-box-shadow-dark-theme);
  -ms-box-shadow: var(--icon-box-shadow-dark-theme);
  color: var(--menu-icon-dark-theme);
}

.dark-theme .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .count-indicator .count {
  background-color: var(--menu-icon-color);
  color: var(--white);
}

.dark-theme .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .input-group-text,
.dark-theme .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .asColorPicker-wrap .input-group-text {
  border-radius: 0 5px 5px 0;
  background: var(--menu-icon-dark-theme);
  color: var(--font-color-dark-theme);
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .asColorPicker-wrap {
  background: none;
}

.dark-theme .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .count-indicator,
.dark-theme .language-selector,
.dark-theme .table-button {
  background-image: none;
  background: var(--component-background-dark-theme);
  box-shadow: var(--icon-box-shadow-dark-theme);
  -webkit-box-shadow: var(--icon-box-shadow-dark-theme);
  -moz-box-shadow: var(--icon-box-shadow-dark-theme);
  -ms-box-shadow: var(--icon-box-shadow-dark-theme);
}

.dark-theme.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
  background: var(--main-background-dark-theme)
}

.dark-theme.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link:hover .menu-title {
  background: var(--main-background-dark-theme)
}

.dark-theme.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
.dark-theme.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
  background: var(--main-background-dark-theme)
}

.dark-theme .breadcrumb-container {
  background: var(--main-background-dark-theme)
}

.dark-theme .landing-card {
  box-shadow: var(--icon-box-shadow-dark-theme);
  -webkit-box-shadow: var(--icon-box-shadow-dark-theme);
  -moz-box-shadow: var(--icon-box-shadow-dark-theme);
  -ms-box-shadow: var(--icon-box-shadow-dark-theme);
}


.dark-theme .card-inverse-primary,
.dark-theme .card-inverse-secondary,
.dark-theme .card-inverse-success,
.dark-theme .card-inverse-info,
.dark-theme .card-inverse-warning,
.dark-theme .card-inverse-danger,
.dark-theme .card-inverse-light,
.dark-theme .card-inverse-dark {
  box-shadow: var(--icon-box-shadow-dark-theme);
  -webkit-box-shadow: var(--icon-box-shadow-dark-theme);
  -moz-box-shadow: var(--icon-box-shadow-dark-theme);
  -ms-box-shadow: var(--icon-box-shadow-dark-theme);
}

.dark-theme .sidebar-absolute:not(.sidebar-hidden) .sidebar {
  box-shadow: var(--icon-box-shadow-dark-theme);
  -webkit-box-shadow: var(--icon-box-shadow-dark-theme);
  -moz-box-shadow: var(--icon-box-shadow-dark-theme);
  -ms-box-shadow: var(--icon-box-shadow-dark-theme);

}

.dark-theme .react-checkbox-tree .rct-options button {
  box-shadow: var(--icon-box-shadow-dark-theme);
  -webkit-box-shadow: var(--icon-box-shadow-dark-theme);
  -moz-box-shadow: var(--icon-box-shadow-dark-theme);
  -ms-box-shadow: var(--icon-box-shadow-dark-theme);
}

.dark-theme .pagination .page-item.active .page-link {
  background-color: var(--main-background-dark-theme) !important;
  box-shadow: var(--icon-box-shadow-dark-theme) !important;
  -webkit-box-shadow: var(--icon-box-shadow-dark-theme) !important;
  -moz-box-shadow: var(--icon-box-shadow-dark-theme) !important;
  -ms-box-shadow: var(--icon-box-shadow-dark-theme) !important;
}

.dark-theme .btn,
.dark-theme .fc button,
.dark-theme .ajax-upload-dragdrop .ajax-file-upload,
.dark-theme .swal2-modal .swal2-buttonswrapper .swal2-styled,
.dark-theme .swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-confirm,
.dark-theme .swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-cancel,
.dark-theme .wizard>.actions a {
  box-shadow: var(--icon-box-shadow-dark-theme) !important;
  -webkit-box-shadow: var(--icon-box-shadow-dark-theme) !important;
  -moz-box-shadow: var(--icon-box-shadow-dark-theme) !important;
  -ms-box-shadow: var(--icon-box-shadow-dark-theme) !important;
  color: var(--font-color-dark-theme);
  background-color: var(--component-background-dark-theme);
  border: none;
}

.dark-theme .btn:hover,
.fc button:hover,
.ajax-upload-dragdrop .ajax-file-upload:hover,
.swal2-modal .swal2-buttonswrapper .swal2-styled:hover,
.wizard>.actions a:hover {
  color: var(--font-color-dark-theme);
}

.dark-theme .br-theme-bars-movie .br-widget .br-current-rating,
.dark-theme .br-theme-bars-reversed .br-widget .br-current-rating,
.dark-theme .br-theme-bars-1to10 .br-widget .br-current-rating,
.dark-theme .br-theme-bars-horizontal .br-widget .br-current-rating {
  color: var(--font-color-dark-theme);
}

.dark-theme .swal2-modal .swal2-title {
  color: var(--font-color-dark-theme);
}

.dark-theme .swal2-modal .swal2-content {
  color: var(--font-color-dark-theme);
}

.dark-theme .editable-form .editable {
  color: var(--font-color-dark-theme);
}

.dark-theme .editable-form .editable-click {
  color: var(--font-color-dark-theme);
}

.dark-theme .sidebar .nav.sub-menu .nav-item .nav-link {
  color: var(--font-color-dark-theme);
}

.dark-theme .sidebar .nav .nav-item.active>.nav-link .menu-title,
.dark-theme .sidebar .nav .nav-item.active>.nav-link .menu-arrow {
  color: var(--font-color-dark-theme);
}

.dark-theme .settings-panel .nav-tabs .nav-item .nav-link {
  color: var(--font-color-dark-theme);
}

.dark-theme .rdt_TableCell,
.dark-theme .rdt_TableCol {
  color: var(--font-color-dark-theme);
}

.dark-theme .rdt_Table .dropdown {
  background-color: var(--main-background-dark-theme);
  box-shadow: var(--icon-box-shadow-dark-theme);
}

.dark-theme .rdt_Table,
.dark-theme .rdt_TableHead,
.dark-theme .rdt_TableRow,
.dark-theme .rdt_TableHeadRow,
.dark-theme .rdt_TableHeader,
.dark-theme .rdt_Pagination,
.dark-theme .bzRnkJ .dark-theme .pagination-last-page svg,
.dark-theme .pagination-next-page svg,
.dark-theme .pagination-first-page svg,
.dark-theme .pagination-previous-page svg {
  color: var(--font-color-dark-theme);
}

.dark-theme .rdt_TableRow:hover {
  background-color: var(--main-background-dark-theme) !important;
  border-bottom: none;
}

.dark-theme .form-control,
.dark-theme .asColorPicker-input,
.dark-theme .dataTables_wrapper select,
.dark-theme .jsgrid .jsgrid-table .jsgrid-filter-row input[type='text'],
.dark-theme .jsgrid .jsgrid-table .jsgrid-filter-row select,
.dark-theme .jsgrid .jsgrid-table .jsgrid-filter-row input[type='number'],
.dark-theme .select2-container--default .select2-selection--single,
.dark-theme .select2-container--default .select2-selection--single .select2-search__field,
.dark-theme .typeahead,
.dark-theme .tt-query,
.dark-theme .tt-hint {
  box-shadow: var(--icon-box-shadow-dark-theme);
  background: var(--main-background-dark-theme) !important;
  color: var(--font-color-dark-theme);
  border: 1px solid var(--input-field-border-dark-theme) !important;
}

.dark-theme .form-control:hover,
.dark-theme .asColorPicker-input:hover,
.dark-theme .dataTables_wrapper select:hover,
.dark-theme .jsgrid .jsgrid-table .jsgrid-filter-row input:hover[type='text'],
.dark-theme .jsgrid .jsgrid-table .jsgrid-filter-row select:hover,
.dark-theme .jsgrid .jsgrid-table .jsgrid-filter-row input:hover[type='number'],
.dark-theme .select2-container--default .select2-selection--single:hover,
.dark-theme .select2-container--default .select2-selection--single .select2-search__field:hover,
.dark-theme .typeahead:hover,
.dark-theme .tt-query:hover,
.dark-theme .tt-hint:hover,
.dark-theme .form-control:active,
.dark-theme .asColorPicker-input:active,
.dark-theme .dataTables_wrapper select:active,
.dark-theme .jsgrid .jsgrid-table .jsgrid-filter-row input:active[type='text'],
.dark-theme .jsgrid .jsgrid-table .jsgrid-filter-row select:active,
.dark-theme .jsgrid .jsgrid-table .jsgrid-filter-row input:active[type='number'],
.dark-theme .select2-container--default .select2-selection--single:active,
.dark-theme .select2-container--default .select2-selection--single .select2-search__field:active,
.dark-theme .typeahead:active,
.dark-theme .tt-query:active,
.dark-theme .tt-hint:active,
.dark-theme .form-control:focus,
.dark-theme .asColorPicker-input:focus,
.dark-theme .dataTables_wrapper select:focus,
.dark-theme .jsgrid .jsgrid-table .jsgrid-filter-row input:focus[type='text'],
.dark-theme .jsgrid .jsgrid-table .jsgrid-filter-row select:focus,
.dark-theme .jsgrid .jsgrid-table .jsgrid-filter-row input:focus[type='number'],
.dark-theme .select2-container--default .select2-selection--single:focus,
.dark-theme .select2-container--default .select2-selection--single .select2-search__field:focus,
.dark-theme .typeahead:focus,
.dark-theme .tt-query:focus,
.dark-theme .tt-hint:focus {
  box-shadow: var(--icon-box-shadow-dark-theme);
}

.dark-theme .auth .auth-form-transparent .form-control,
.dark-theme .auth .auth-form-transparent .asColorPicker-input,
.dark-theme .auth .auth-form-transparent .dataTables_wrapper select,
.dark-theme .dataTables_wrapper .auth .auth-form-transparent select,
.dark-theme .auth .auth-form-transparent .jsgrid .jsgrid-table .jsgrid-filter-row input[type='text'],
.dark-theme .jsgrid .jsgrid-table .jsgrid-filter-row .auth .auth-form-transparent input[type='text'],
.dark-theme .auth .auth-form-transparent .jsgrid .jsgrid-table .jsgrid-filter-row select,
.dark-theme .jsgrid .jsgrid-table .jsgrid-filter-row .auth .auth-form-transparent select,
.dark-theme .auth .auth-form-transparent .jsgrid .jsgrid-table .jsgrid-filter-row input[type='number'],
.dark-theme .jsgrid .jsgrid-table .jsgrid-filter-row .auth .auth-form-transparent input[type='number'],
.dark-theme .auth .auth-form-transparent .select2-container--default .select2-selection--single,
.dark-theme .select2-container--default .auth .auth-form-transparent .select2-selection--single,
.dark-theme .auth .auth-form-transparent .select2-container--default .select2-selection--single .select2-search__field,
.dark-theme .select2-container--default .select2-selection--single .auth .auth-form-transparent .select2-search__field,
.dark-theme .auth .auth-form-transparent .typeahead,
.dark-theme .auth .auth-form-transparent .tt-query,
.dark-theme .auth .auth-form-transparent .tt-hint,
.dark-theme .auth .auth-form-transparent .input-group-text {
  border-color: transparent;
  box-shadow: var(--icon-box-shadow-dark-theme);
  background-color: var(--component-background-dark-theme) !important;
  -webkit-box-shadow: 0 0 0 30px var(--component-background-dark-theme) inset !important;
  -webkit-color: var(--font-color-dark-theme) !important;
  color: var(--font-color-dark-theme) !important;
}

.dark-theme input::-webkit-input-placeholder,
.dark-theme input:not(::placeholder) {
  color: var(--font-color-dark-theme) !important;
}

.dark-theme .text-black {
  color: var(--font-color-dark-theme) !important;
}

.dark-theme ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.dark-theme .sidebar .nav.sub-menu .nav-item .nav-link.active {
  color: var(--active-link-color-dark-item);
  background: var(--active-link-background-color-dark-item);
  box-shadow: var(--icon-box-shadow-dark-theme);
  -webkit-box-shadow: var(--icon-box-shadow-dark-theme);
  -moz-box-shadow: var(--icon-box-shadow-dark-theme);
  -ms-box-shadow: var(--icon-box-shadow-dark-theme);
}

.dark-theme .btn-light {
  background-color: var(--btn-light-background-dark-theme);
  border-color: var(--btn-light-background-dark-theme);
}

.dark-theme .btn-light:hover {
  background-color: var(--active-link-background-dark-theme);
  border-color: var(--active-link-background-dark-theme);
}

.dark-theme .btn-light:focus,
.dark-theme .btn-light.focus {
  background-color: var(--active-link-background-dark-theme);
  border-color: var(--active-link-background-dark-theme);
  box-shadow: none;
}

.dark-theme .btn-light.disabled,
.dark-theme .btn-light:disabled {
  background-color: var(--btn-light-background);
  border-color: var(--btn-light-background);
}

.dark-theme .btn-primary {
  box-shadow: var(--icon-box-shadow-dark-theme) !important;
  background-color: var(--btn-primary-dark-theme);
}

.dark-theme .btn-primary:hover {
  background-color: var(--btn-primary-hover-dark-theme);
}

.dark-theme .border-vertical,
.dark-theme .border-horizontal {
  background-color: var(--card-divider-dark-theme);
  box-shadow: none;
}

.dark-theme .icon-wrap {
  box-shadow: var(--icon-box-inset-shadow-dark-theme);
  background-color: var(--main-background-dark-theme);
  -webkit-box-shadow: var(--main-background-dark-theme);
  -moz-box-shadow: var(--main-background-dark-theme);
  -ms-box-shadow: var(--main-background-dark-theme);
  color: var(--menu-icon-dark-theme);
}

.dark-theme .modal-content {
  background-color: var(--main-background-dark-theme) !important;
  border: none !important;
}

.dark-theme .modal-header {
  background-color: var(--main-background-dark-theme) !important;
  border-bottom: 1px solid var(--component-background-dark-theme) !important;
  color: var(--font-color-dark-theme);
}

.dark-theme .modal-body {
  color: var(--font-color-dark-theme);
}

.dark-theme .modal-footer {
  border-top: 1px solid var(--component-background-dark-theme) !important;
}

.dark-theme .react-select__menu {
  background-color: var(--main-background-dark-theme);
}

.dark-theme .btn-danger {
  color: var(--white);
  background-color: var(--btn-danger-dark-theme);
  border-color: var(--btn-danger-dark-theme);
}

.dark-theme .btn-danger:hover {
  color: var(--white);
  background-color: var(--btn-danger-hover-dark-theme);
  border-color: var(--btn-danger-hover-dark-theme);
}

.dark-theme .btn-danger:active {
  color: var(--white);
  background-color: var(--btn-danger-hover-dark-theme);
  border-color: var(--btn-danger-hover-dark-theme);
}

.dark-theme .form-check-input:checked {
  background-color: var(--btn-primary-dark-theme) !important;
}

.dark-theme .text-success {
  color: var(--success-dark-theme) !important;
}

.dark-theme .react-select__single-value {
  color: var(--font-color-dark-theme) !important;
}

.dark-theme .fyrdjl {
  fill: var(--font-color-dark-theme) !important;
}

.dark-theme .fyrdjl:disabled {
  fill: var(--gray) !important;
}

.dark-theme .react-checkbox-tree .rct-options button {
  color: var(--menu-icon-dark-theme);
}

.navbar-nav-right .nav-item a:active {
  color: var(--font-color-dark-theme);
}

.dark-theme .table-button {
  color: var(--font-color-dark-theme);
}

.dark-theme .table-button:hover {
  color: inherit;
}

.dark-theme .vehicle-brand-name {
  box-shadow: var(--icon-box-inset-shadow-dark-theme);
  border: 1px solid transparent;
}

.dark-theme .vehicle-brand-name.selected {
  border: 1px solid var(--menu-icon-color);
  color: var(--menu-icon-color);
}